














































import { Mixins, Component } from 'vue-property-decorator';
import FormMixin from '@/app/core/mixins/form.mixin';
import { atLeast, minLength, required } from '@/app/core/validation';
import CompleteRegistrationDto from '../dto/complete-registration.dto';

@Component
export default class CompleteRegistrationForm extends Mixins(FormMixin) {
  private showPassword = false;
  private showConfirmPassword = false;

  protected data: CompleteRegistrationDto = {
    password: '',
    profile: {
      firstname: '',
      lastname: '',
    },
  };

  protected rules = {
    firstname: [required],
    lastname: [required],
    password: [
      required,
      minLength(8),
      atLeast(1).digits,
      atLeast(1).lowercaseLetters,
      atLeast(1).uppercaseLetters,
    ],
  };
}
