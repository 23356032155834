import api from '@/app/core/services/api.service';
import ResetPasswordDto from '../dto/reset-password.dto';
import CompleteRegistrationDto from '../dto/complete-registration.dto';
import RequestPasswordResetDto from '../dto/request-password-reset.dto';

const completeRegistration = (accessToken: string, dto: CompleteRegistrationDto) => (
  api.put('/user/complete-registration', dto, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
);

const requestPasswordReset = (dto: RequestPasswordResetDto) => (
  api.put('/user/request-password-reset', dto)
);

const resetPassword = (accessToken: string, dto: ResetPasswordDto) => (
  api.put('/user/reset-password', dto, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
);

export default {
  resetPassword,
  completeRegistration,
  requestPasswordReset,
};
