

















































import { Component, Mixins } from 'vue-property-decorator';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import userService from '../services/user.service';
import CompleteRegistrationDto from '../dto/complete-registration.dto';
import CompleteRegistrationForm from '../components/complete-registration-form.vue';

@Component({
  components: {
    CompleteRegistrationForm,
  },
  metaInfo(this: CompleteRegistration) {
    return { title: this.$t('pageTitle.completeRegistration').toString() };
  },
})
export default class CompleteRegistration extends Mixins(HandlesErrorMixin) {
  private loading = false;
  private showSuccessIcon = false;
  private steps = {
    completeRegistrationForm: 0,
    successMessage: 1,
  };
  private step = this.steps.completeRegistrationForm;

  registrationCompleted() {
    setTimeout(() => {
      this.showSuccessIcon = true;
    }, 300);

    this.step = this.steps.successMessage;
  }

  async onSubmit(data: CompleteRegistrationDto) {
    this.loading = true;
    const { accessToken } = this.$route.params;

    try {
      await userService.completeRegistration(accessToken, data);
      this.registrationCompleted();
    } catch (error) {
      if (this.isForbiddenError(error)) {
        const message = this.$t('error.invalidRegistrationLink').toString();

        this.$notify.error({
          message,
          options: { lefty: true },
        });
      } else {
        this.handleError(error);
      }
    } finally {
      this.loading = false;
    }
  }
}
